.open-names-search {
  background: white;
  border-radius: 3px;
}

.open-names-search__option-name,
.open-names-search__option-locality {
  display: block;
}

.open-names-search__option-locality {
  font-size: 0.8em;
}
